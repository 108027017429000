import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { container, errorGraphic } from "../styles/404.module.css"

const ErrorPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Oh no, it's a 404" />
      <div className={container}>
        <h1>Uh oh, it's a 404 Error</h1>
        <img
          src={data.file.publicURL}
          alt={data.file.name}
          className={errorGraphic}
        />
        <h3>This means the page you requested cannot be found</h3>
        <Link to="/">Return to homepage</Link>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query {
    file(relativePath: { eq: "images/404.svg" }) {
      name
      publicURL
    }
  }
`

export default ErrorPage
